// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-company-about-js": () => import("./../src/pages/company/about.js" /* webpackChunkName: "component---src-pages-company-about-js" */),
  "component---src-pages-company-contact-js": () => import("./../src/pages/company/contact.js" /* webpackChunkName: "component---src-pages-company-contact-js" */),
  "component---src-pages-features-js": () => import("./../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-privacy-js": () => import("./../src/pages/legal/privacy.js" /* webpackChunkName: "component---src-pages-legal-privacy-js" */),
  "component---src-pages-legal-terms-js": () => import("./../src/pages/legal/terms.js" /* webpackChunkName: "component---src-pages-legal-terms-js" */),
  "component---src-pages-support-faq-js": () => import("./../src/pages/support/faq.js" /* webpackChunkName: "component---src-pages-support-faq-js" */),
  "component---src-pages-support-start-js": () => import("./../src/pages/support/start.js" /* webpackChunkName: "component---src-pages-support-start-js" */)
}

