module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"RealtyStack","short_name":"RealtyStack","start_url":"/","background_color":"#002140","theme_color":"#002140","display":"standalone","icon":"src/assets/Asset 5.svg","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"2749b35c006d0f345c3db98ab98f469e"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-154934158-1","head":false,"anonymize":true,"respectDNT":false,"pageTransitionDelay":0,"defer":false,"sampleRate":100,"cookieDomain":"realtystack.io"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
